import React, {useEffect, useState} from "react"
import { Link, graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import StructuredData from "../components/structuredData"
import Image from "gatsby-image"
import { useExternalScript } from "../hooks/use-external-script";

const COMMENT_URL = "https://commentoplusplus-production-4fa8.up.railway.app/js/commento.js"

const Comments = () => {
    const commentState = useExternalScript(COMMENT_URL)
    return commentState === "ready" ? <></> : "Loading..."
}

const BlogPostTemplate = ({ data, pageContext, location }) => {
  const post = data.markdownRemark
  const { title: siteTitle, description: siteDescription, slug, disqusShortname, author } = data.site.siteMetadata
  const avatar = data?.avatar?.childImageSharp?.fixed
  const { previous, next, lang } = pageContext
  const isPostTemplate = post.frontmatter.template === "post"
  const isNextPostTemplate = next && next.node.frontmatter.template === "post"
  const [showComments, setShowComments] = useState(false)

  return (
    <Layout avatar={avatar} lang={lang} location={location} title={siteTitle} description={siteDescription}>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <StructuredData
        url={location.href}
        date={data.dateTime.frontmatter.date}
        modified={data.modifiedDateTime.frontmatter.modified}
        article={true}
        lang={lang}
        title={post.frontmatter.title}
        description={post.frontmatter.description}
      />
      <section className="postContainer">
        <aside>
          <h2>
              Related Posts
          </h2>
          <nav>
            <ul>
              {data.relatedPosts.edges.map(edge => {
                return <li>
                  <a href={edge.node.fields.slug}>
                    {edge.node.frontmatter.title}
                  </a>
                </li>
              })}
            </ul>
          </nav>
        </aside>
        <section>

        <article itemScope className="blog-post">
          <header>
            <h1 itemProp="headline">{post.frontmatter.title}</h1>
            <p id="description">
              <i>{post.frontmatter.description}</i>
            </p>
            {post.frontmatter.keywords && (
              <p>
                <small><strong>Keywords</strong>: <i>{post.frontmatter.keywords}</i></small>
              </p>
            )}
            {author?.name && (
              <div className="author">
                {data?.avatar?.childImageSharp?.fixed && (
                  <Image
                    fixed={data?.avatar?.childImageSharp?.fixed}
                    alt={author?.name || ``}
                    className="bio-avatar"
                    imgStyle={{
                      borderRadius: `50%`,
                    }}
                  />
                )}
                <div>
                  By {author.name} &nbsp;
                  {/* <a href="https://twitter.com/CarmenCincotti?ref_src=twsrc%5Etfw" class="twitter-follow-button" data-show-screen-name="false" data-show-count="false">Follow @CarmenCincotti</a> */}
                </div>
              </div>
            )}
            <time datetime={data.modifiedDateTime.frontmatter.modified || data.dateTime.frontmatter.date}>
              <small className="timestamp">
                {post.frontmatter.date}
                {post.frontmatter.modified && (
                  <span>
                    Updated {post.frontmatter.modified}
                  </span>
                )}
              </small>
            </time>
          </header>
          <section
            dangerouslySetInnerHTML={{
              __html: post.html.replace(/href="https\:\/\//g, "target='_blank' href=\"https\:\/\/"),
            }}
            itemProp="articleBody"
          />
          <hr />
        </article>
        <h2>
          Comments for {post.frontmatter.title}
        </h2>
        {showComments === false && (
          <div>
            <button onClick={() => setShowComments(true)}>Show Comments</button>
          </div>
        )}
        {showComments && (
          <Comments />
        )}
        <div id="commento"></div>
        <hr />
        {isPostTemplate && (
          <>
            <nav className="blog-post-nav">
              <ul
                style={{
                  display: `flex`,
                  flexWrap: `wrap`,
                  justifyContent: `space-between`,
                  listStyle: `none`,
                  padding: 0,
                }}
              >
                <li className="blog-post-nav-prev">
                  {previous && (
                    <Link to={previous.node.fields.slug} rel="prev">
                      ← {previous.node.frontmatter.title}
                    </Link>
                  )}
                </li>
                {isNextPostTemplate && (
                  <li className="blog-post-nav-next">
                    {next && (
                      <Link to={next.node.fields.slug} rel="next">
                        {next.node.frontmatter.title} →
                      </Link>
                    )}
                  </li>
                )}
              </ul>
            </nav>
            <hr />
          </>
        )}
        {isPostTemplate && (
          <Bio />
        )}
        {isPostTemplate && (
          <div class="box idea">
            <h2>
              Contribute
            </h2>
            Interested in contributing to Carmen's Graphics Blog? <a href="/submit-content/">Click here for details!</a>
          </div>
        )}
        </section>
      </section>

      {/* {isPostTemplate && <DiscussionEmbed {...disqusConfig} />} */}

    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!, $tags: [Int], $lang: String!) {
    site {
      siteMetadata {
        author {
          name
        }
        social {
          twitter
        }
        title
        description
        disqusShortname
      }
    }
    avatar: file(absolutePath: { regex: "/profile-pic.jpg/" }) {
      childImageSharp {
        fixed(width: 50, height: 50, quality: 95) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      rawMarkdownBody
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        modified(formatString: "MMMM DD, YYYY")
        description
        template
        keywords
      }
    }
    dateTime: markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        date
      }
    }
    modifiedDateTime: markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        modified
      }
    }

    relatedPosts: allMarkdownRemark(
      sort: {fields: [frontmatter___date], order: DESC}
      limit: 1000
      filter: {
        frontmatter: {
          tag: {
            in: $tags,
          }
        }
        fields: {
        langKey: {
            eq: $lang
          }
        }
      }
    ) {
      edges {
        node {
          fields {
            slug
            langKey
          }
          frontmatter {
            date(formatString: "DD MMMM, YYYY")
            title
            template
            tag
          }
          timeToRead
        }
      }
    }
  }
`
