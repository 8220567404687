import React from "react"
import { Helmet } from "react-helmet"

const StructuredData = ({ article, lang, url, title, description, date }) => {
  return (
    <Helmet>
      {(article ? true : null) && (
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "BlogPosting",
            mainEntityOfPage: {
              "@type": "WebPage",
              "@id": url,
            },
            url,
            inLanguage: lang,
            headline: title,
            description,
            author: {
              "@type": "Person",
              name: "Carmen Cincotti",
            },
            datePublished: date,
            dateModified: date,
          })}
        </script>
      )}
    </Helmet>
  )
}

export default StructuredData
